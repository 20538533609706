import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { AdminDataContext } from "../../../context/adminContext";
import { usePost } from "../../../hooks/usePost";
import { DashboardAnalyticsRes } from "../../../interfaces/admin.interface";
import {
  getAdminAnalytics,
  getAllShipmentsByDateRange,
  AdminBodyRanges,
  AdminBodyRangesAndCountry,
  getShipmentsToday,
  getDailyShipmentsByService,
} from "../../../services/admin_s";
import { FilterRequest } from "../../../services/user_d";
import { useGet } from "../../../hooks/useGet";

export const useSection1Dashboard = () => {
  const [registerDate, setRegisterDate] = useState<moment.Moment[]>([]);
  const [filter] = useState<FilterRequest>({});
  const { country } = useContext(AdminDataContext);
  const [ranges] = useState<AdminBodyRanges>({
    range1: new Date(moment().startOf("month").format("YYYY-MM-DD 00:00:00")),
    range2: new Date(moment().endOf("day").format("YYYY-MM-DD 23:59:59")),
  });

  const {
    fetchData,
    data: adminAnalytics,
    isLoading: isFetchingAdminAnalytics,
  } = usePost<FilterRequest, DashboardAnalyticsRes>(getAdminAnalytics);

  const {
    fetchData: fetchDataShipmentsByDateRange,
    data: dataShiptmentsByDateRange,
    isLoading: isLoadingShipmentsByDateRange,
  } = usePost(getAllShipmentsByDateRange);

  // const {
  //   fetchData: fetchDataShipmentsByYears,
  //   data: dataShiptmentsByYears,
  //   isLoading: isLoadingShipmentsByYears,
  // } = usePost(getAllShipmentsByYears);

  const { data: getShipmentsTodaySupp, refetch: refetchGetShipmentsTodaySupp } = useGet(getShipmentsToday(country));
  const { data: getDailyShipmentsService, fetchData: refetchGetDailyShipmentsByService,isLoading: isLoadingGetDailyShipmentsByService } = usePost(getDailyShipmentsByService);

  // const {data: verifyServices } = useGet(getVerifyServices())

  const getDataByDateRange = useCallback(
    async (rangesAndCountry: AdminBodyRangesAndCountry) => {
      await fetchDataShipmentsByDateRange(rangesAndCountry);
    },
    [fetchDataShipmentsByDateRange]
  );

  // const getDataByYears = useCallback(
  //   async (country: AdminBodyCountry) => {
  //     await fetchDataShipmentsByYears(country);
  //   },
  //   [fetchDataShipmentsByYears]
  // );

  const isLoading = isFetchingAdminAnalytics;

  const getData = useCallback(
    async (filter: FilterRequest) => {
      await fetchData(filter);
    },
    [fetchData]
  );

  useEffect(() => {
    const currentDate = moment();
    setRegisterDate([
      moment(currentDate).startOf("month"),
      moment(currentDate).endOf("day"),
    ]);
  }, []);

  useEffect(() => {
    // console.log('Country -------->', country)
    // setFilter({
    //   ...filter,
    //   "origin.country": country ?? 'MX',
    // });
    refetchGetShipmentsTodaySupp()
    getData({ "origin.country": country });
  }, [country]); //eslint-disable-line

  useEffect(() => {
    getDataByDateRange({ ranges, country });
  }, [filter, registerDate, ranges, country]); //eslint-disable-line

  return {
    adminAnalytics,
    country,
    isLoading,
    isLoadingShipmentsByDateRange,
    dataShiptmentsByDateRange,
    getShipmentsTodaySupp,
    getDailyShipmentsService,
    refetchGetDailyShipmentsByService,
    isLoadingGetDailyShipmentsByService
  };
};