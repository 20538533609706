import { useState, useEffect, useContext } from "react";
import Chart from "react-apexcharts";
import CardContainer from "../../../../components/Containers/CardContainer";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { Select } from "antd";
import { AppContext } from "../../../../context/appContext";

type Props = {
  filteredData: any;
  isLoading: boolean;
  fetchData: any;
};

const GraphShipemtsByDate = ({ isLoading, filteredData, fetchData }: Props) => {
  const [updatedData, setUpdatedData] = useState<any>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [years, setYears] = useState<number[]>([]);
  const [year, setYear] = useState<number | undefined>(undefined);
  const [months, setMonths] = useState<string[]>([]);
  const [month, setMonth] = useState<string | undefined>(undefined);
  const [selectorValue, setSelectorValue] = useState<string>("GENERAL");
  const uniqueCarriers = ["DHL", "FEDEX", "UPS", "ESTAFETA", "PAQUETEXPRESS", "SENDEX", 
    "BORZO", "CARSSA", "QUIKEN", "AMPM", "REDPACK", "IVOY", "JTEXPRESS", "TRESGUERRAS",
    "TREGGO", "PICKIT", "VENCEDOR", "IMILE", "NOVENTA9MINUTOS"];
  const { userData } = useContext(AppContext);
  const { Option } = Select;
  
  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      setUpdatedData(filteredData);
      const maxYear = Math.max(...filteredData.map((item: any) => item.year));
      setYear(maxYear);
    }
  }, [filteredData]);

  useEffect(() => {
    const handleData = async () => {
      if (updatedData.length === 0) {
        setData([]);
        return;}
  
      // Ordenar y establecer los años
      const sortedYears = updatedData.map((item: any) => item.year).sort((a: any, b: any) => b - a);
      setYears(sortedYears);
  
      if (!year) return;
  
      const data = updatedData.find((item: any) => item.year === year);
      const monthsPerYear = data?.months;
      const monthOrder = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
      ];
  
      // Ordenar los meses
      const sortedMonths = Object.keys(monthsPerYear || {}).sort((a, b) => {
        return monthOrder.indexOf(a) - monthOrder.indexOf(b);
      });
      setMonths(sortedMonths);
  
      // Determinar las categorías y los datos a mostrar
      if (selectorValue === "GENERAL") {
        if (!month || month === "option1") {
          setCategories(sortedMonths);
          const generalData = sortedMonths.map((month) => monthsPerYear[month].total);
          setData(generalData);
        } else {
          const days = monthsPerYear[month].days;
          setCategories(Object.keys(days));
          setData(Object.values(days));
        }
      } else if (month && month !== "option1") {
        const days = monthsPerYear[month].days;
        setCategories(Object.keys(days));
        setData(Object.values(days));
      } else {
        setCategories(sortedMonths);
        const generalData = sortedMonths.map((month) => monthsPerYear[month].total);
        setData(generalData);
      }
    };
  
    handleData();
  }, [updatedData, year, month, selectorValue]);

  useEffect(() => {
    const fetchDataByCarrier = async () => {
      if (selectorValue !== "GENERAL") {
        const dataFetched = await fetchData({
          carrier: selectorValue.toLowerCase(),
        });
        setUpdatedData(dataFetched.data && dataFetched.data.length > 0 ? dataFetched.data : []);
      }
      };
      fetchDataByCarrier();
  }, [selectorValue]); //eslint-disable-line

  const options = {
    chart: {
      id: `month-bar`,
    },
    xaxis: {
      categories,
    },
  };

  const series = [
    {
      name: "series",
      data,
    },
  ];

  return (
    <>
      {(userData?.roles[0] === "ADMIN" || userData?.roles[0] === "DEV") && (
        <CardContainer
          cardStyle={{ marginTop: "20px", textAlign: "center" }}
          title="Envios por fecha"
          titleStyle={{ placeItems: "center" }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Select
              value={year}
              onChange={setYear}
              style={{ width: "100%", textAlign: "center" }}
            >
              <Option value="option1" style={{ textAlign: "center" }}>
                Año
              </Option>
              {years.map((year) => (
                <Option key={year} value={year} style={{ textAlign: "center" }}>
                  {year}
                </Option>
              ))}
            </Select>
            <Select
              value={month}
              onChange={setMonth}
              style={{ width: "100%", textAlign: "center" }}
            >
              <Option value="option1" style={{ textAlign: "center" }}>
                Mes
              </Option>
              {months.map((m) => (
                <Option key={m} value={m} style={{ textAlign: "center" }}>
                  {m}
                </Option>
              ))}
            </Select>
            <Select
              value={selectorValue}
              onChange={setSelectorValue}
              style={{ width: "100%", textAlign: "center" }}
            >
              <Option value="option1" style={{ textAlign: "center" }}>
                General
              </Option>
              {uniqueCarriers.map((carrier) => (
                <Option key={carrier} value={carrier} style={{ textAlign: "center" }}>
                  {carrier}
                </Option>
              ))}
            </Select>
          </div>
          {isLoading ? (
            <LoadingAnimation animationType="small" />
          ) : (
            <Chart options={options} series={series} type="bar" width="100%" />
          )}
        </CardContainer>
      )}
    </>
  );
};

export default GraphShipemtsByDate;