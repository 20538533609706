// Carriers Logos
import estafeta from "../assets/images/ParcelLogos/estafeta.png";
import carssa from "../assets/images/ParcelLogos/carssa.png";
import quiken from "../assets/images/ParcelLogos/quiken.png";
import scm from "../assets/images/ParcelLogos/scm.png";
import ampm from "../assets/images/ParcelLogos/ampm.png";
import sendex from "../assets/images/ParcelLogos/sendex.png";
import dhl from "../assets/images/ParcelLogos/dhl.png";
import ivoy from "../assets/images/ParcelLogos/ivoy.png";
import fedex from "../assets/images/ParcelLogos/fedex.png";
import mensajerosUrbanos from "../assets/images/ParcelLogos/mensajerosUrbanos.png";
import noventa9minutos from "../assets/images/ParcelLogos/noventa9minutos.png";
import paquetexpress from "../assets/images/ParcelLogos/paquetexpress.png";
import tracusa from "../assets/images/ParcelLogos/tracusa.png";
import uber from "../assets/images/ParcelLogos/uber.png";
import ups from "../assets/images/ParcelLogos/ups.png";
import coordinadora from "../assets/images/ParcelLogos/coordinadora.png";
import redServi from "../assets/images/ParcelLogos/redServi.png";
import serviEntrega from "../assets/images/ParcelLogos/serviEntrega.png";
import aExpress from "../assets/images/ParcelLogos/aExpress.png";
import jtexpress from "../assets/images/ParcelLogos/jtexpress.png";
import borzo from "../assets/images/ParcelLogos/borzo.png";
import entrega from "../assets/images/ParcelLogos/entrega.png";
import treggo from "../assets/images/ParcelLogos/treggo.png";
import imile from "../assets/images/ParcelLogos/imile.png";
import vencedor from "../assets/images/ParcelLogos/vencedor.webp";
import enviaco from "../assets/images/ParcelLogos/enviaco.webp";
import tcc from "../assets/images/ParcelLogos/tcc.webp";

//Carries dashboard logos
import estafetaDashboard from "../assets/images/ParcelDashboard/estafeta.webp";
import carssaDashboard from "../assets/images/ParcelDashboard/carssa.png";
import quikenDashboard from "../assets/images/ParcelDashboard/quiken.webp";
import scmDashboard from "../assets/images/ParcelDashboard/scm.png";
import ampmDashboard from "../assets/images/ParcelDashboard/ampm.webp";
import sendexDashboard from "../assets/images/ParcelDashboard/sendex.webp";
import dhlDashboard from "../assets/images/ParcelDashboard/dhl.webp";
import ivoyDashboard from "../assets/images/ParcelDashboard/ivoy.png";
import fedexDashboard from "../assets/images/ParcelDashboard/fedex.webp";
import mensajerosUrbanosDashboard from "../assets/images/ParcelDashboard/mensajerosUrbanos.png";
import noventa9minutosDashboard from "../assets/images/ParcelDashboard/noventa9minutos.webp";
import paquetexpressDashboard from "../assets/images/ParcelDashboard/paquetexpress.webp";
import tracusaDashboard from "../assets/images/ParcelDashboard/tracusa.png";
import uberDashboard from "../assets/images/ParcelDashboard/uber.png";
import upsDashboard from "../assets/images/ParcelDashboard/ups.webp";
import jtexpressDashboard from "../assets/images/ParcelDashboard/jtexpress.webp";
import borzoDashboard from "../assets/images/ParcelDashboard/borzo.png";
import entregaDashboard from "../assets/images/ParcelDashboard/entrega.png";
import interrapidisimoDashboard from "../assets/images/ParcelDashboard/interrapidisimo.png";
import treggoDashboard from "../assets/images/ParcelDashboard/treggo.png";
import testDashboard from "../assets/images/ParcelDashboard/testDrenvio.png";
import tccDashboard from "../assets/images/ParcelDashboard/tcc.webp";
import enviacoDashboard from "../assets/images/ParcelDashboard/enviaco.webp";
import imileDashboard from "../assets/images/ParcelDashboard/imile.webp";
import vencedorDashboard from "../assets/images/ParcelDashboard/vencedor.webp";
import redserviDashboard from "../assets/images/ParcelDashboard/redServi.webp";

interface MainControllerData {
  name: string;
  image: string;
  key: string;
  count?: number;
}
export const mainControllerData: MainControllerData[] = [
  { name: "DhlController", image: "dhl", key: "dhl" },
  { name: "EnviaController", image: "envia", key: "envia" },
  { name: "EstafetaController", image: "estafeta", key: "estafeta" },
  { name: "FedexController", image: "fedex", key: "fedex" },
  { name: "JTExpressController", image: "jtexpress", key: "jtexpress" },
  { name: "Noventa9MinutosController", image: "99minutos", key: "99minutos" },
  { name: "Pak2GoController", image: "pak2go", key: "pak2go" },
  { name: "QuikenController", image: "quiken", key: "quiken" },
  { name: "BorzoController", image: "borzo", key: "borzo" },
  { name: "ShipAndGoController", image: "shipandgo", key: "shipandgo" },
  { name: "ShippoController", image: "shippo", key: "shippo" },
  { name: "TPAKController", image: "tpak", key: "tpak" },
  {
    name: "CoordinadoraController",
    image: "coordinadora",
    key: "coordinadora",
  },
  { name: "DhlImportController", image: "dhlimport", key: "dhlimport" },
  { name: "TreggoController", image: "treggo", key: "treggo" },
  { name: "OpenPayController", image: "openpay", key: "openpay" },
  { name: "ZiguPayContrller", image: "zigupay", key: "zigupay" },
  {
    name: "InterrapidisimoController",
    image: "interrapidisimo",
    key: "interrapidisimo",
  },
  {
    name: "OTPVerificationController",
    image: "otpverification",
    key: "otpverification",
  },
  {
    name: "NextShippingController",
    image: "nextshipping",
    key: "nextshipping",
  },
  { name: "TresguerrasController", image: "tresguerras", key: "tresguerras" },
  { name: "UberController", image: "uber", key: "uber" },
  { name: "PickitController", image: "pickit", key: "pickit" },
  { name: "UpsController", image: "ups", key: "ups" },
  { name: "FacturaController", image: "factura", key: "factura" },
  { name: "FrontError", image: "fronterror", key: "fronterror" },
];

export const dashErrorsObject = {
  FedexController: {
    imgSrc: fedex,
    activated: true,
    key: "FedexController",
    country: ["MX", "CO", "CA", "US"],
    commercial_name: "Fedex",
    image: (
      <img
        alt="fedex"
        src={fedex}
        style={{
          width: "auto",
          height: "70%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: fedexDashboard,
  },
  DhlController: {
    imgSrc: dhl,
    activated: true,
    key: "dhl",
    country: ["MX", "CO", "CA", "US"],
    commercial_name: "DHL",
    image: (
      <img
        alt="dhl"
        src={dhl}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: dhlDashboard,
  },
  EstafetaController: {
    imgSrc: estafeta,
    activated: true,
    key: "estafeta",
    commercial_name: "Estafeta",
    country: ["MX"],
    image: (
      <img
        alt="estafeta"
        src={estafeta}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: estafetaDashboard,
  },
  carssa: {
    imgSrc: carssa,
    activated: true,
    key: "carssa",
    commercial_name: "Carssa",
    country: ["MX"],
    image: (
      <img
        alt="carssa"
        src={carssa}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: carssaDashboard,
  },
  QuikenController: {
    imgSrc: quiken,
    activated: true,
    key: "quiken",
    commercial_name: "Quiken",
    country: ["MX"],
    image: (
      <img
        alt="quiken"
        src={quiken}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: quikenDashboard,
  },
  scm: {
    imgSrc: scm,
    activated: true,
    key: "scm",
    commercial_name: "SCM",
    country: ["MX"],
    image: (
      <img
        alt="scm"
        src={scm}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: scmDashboard,
  },
  sendex: {
    imgSrc: sendex,
    activated: true,
    key: "sendex",
    commercial_name: "Sendex",
    country: ["MX"],
    image: (
      <img
        alt="sendex"
        src={sendex}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: sendexDashboard,
  },
  ampm: {
    imgSrc: ampm,
    activated: true,
    key: "ampm",
    commercial_name: "AMPM",
    country: ["MX"],
    image: (
      <img
        alt="ampm"
        src={ampm}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: ampmDashboard,
  },
  ivoy: {
    imgSrc: ivoy,
    activated: true,
    key: "ivoy",
    commercial_name: "Ivoy",
    country: ["MX", "CO"],
    image: (
      <img
        alt="ivoy"
        src={ivoy}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: ivoyDashboard,
  },
  mensajerosUrbanos: {
    imgSrc: mensajerosUrbanos,
    activated: true,
    key: "mensajerosUrbanos",
    commercial_name: "Mensajeros Urbanos",
    country: ["MX", "CO"],
    image: (
      <img
        alt="mensajerosUrbanos"
        src={mensajerosUrbanos}
        style={{
          width: "auto",
          height: "60%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: mensajerosUrbanosDashboard,
  },
  noventa9Minutos: {
    imgSrc: noventa9minutos,
    activated: true,
    key: "noventa9Minutos",
    commercial_name: "99 minutos",
    country: ["MX", "CO"],
    image: (
      <img
        alt="noventa9minutos"
        src={noventa9minutos}
        style={{
          width: "auto",
          height: "50%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: noventa9minutosDashboard,
  },
  paquetexpress: {
    imgSrc: paquetexpress,
    activated: true,
    key: "paquetexpress",
    commercial_name: "Paquetexpress",
    country: ["MX"],
    image: (
      <img
        alt="paquetexpress"
        src={paquetexpress}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: paquetexpressDashboard,
  },
  tracusa: {
    imgSrc: tracusa,
    activated: true,
    key: "tracusa",
    commercial_name: "Tracusa",
    country: ["MX"],
    image: (
      <img
        alt="tracusa"
        src={tracusa}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: tracusaDashboard,
  },
  uber: {
    imgSrc: uber,
    activated: true,
    key: "uber",
    commercial_name: "Uber",
    country: ["MX"],
    image: (
      <img
        alt="uber"
        src={uber}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: uberDashboard,
  },
  ups: {
    imgSrc: ups,
    activated: true,
    key: "ups",
    commercial_name: "UPS",
    country: ["MX", "CA", "US"],
    image: (
      <img
        alt="ups"
        src={ups}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: upsDashboard,
  },
  aExpress: {
    imgSrc: aExpress,
    activated: true,
    key: "aExpress",
    commercial_name: "AEXpress",
    country: ["CO"],
    image: (
      <img
        alt="aExpress"
        src={aExpress}
        style={{
          width: "auto",
          height: "77%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: aExpress,
  },
  coordinadora: {
    imgSrc: coordinadora,
    activated: true,
    key: "coordinadora",
    commercial_name: "Coordinadora",
    country: ["CO"],
    image: (
      <img
        alt="coordinadora"
        src={coordinadora}
        style={{
          width: "auto",
          height: "70%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: coordinadora,
  },
  redServi: {
    imgSrc: redServi,
    activated: true,
    key: "redServi",
    commercial_name: "Red Service",
    country: ["CO"],
    image: (
      <img
        alt="redServi"
        src={redServi}
        style={{
          width: "auto",
          height: "80%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: redserviDashboard,
  },
  serviEntrega: {
    imgSrc: serviEntrega,
    activated: true,
    key: "serviEntrega",
    commercial_name: "ServiEntrega",
    country: ["CO"],
    image: (
      <img
        alt="serviEntrega"
        src={serviEntrega}
        style={{
          width: "auto",
          height: "100%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: serviEntrega,
  },
  jtexpress: {
    imgSrc: jtexpress,
    activated: true,
    key: "jtexpress",
    commercial_name: "Jtexpress",
    country: ["MX"],
    image: (
      <img
        alt="jtexpress"
        src={jtexpress}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: jtexpressDashboard,
  },
  borzo: {
    imgSrc: borzo,
    activated: true,
    key: "borzo",
    commercial_name: "borzo",
    country: ["MX"],
    image: (
      <img
        alt="borzo"
        src={borzo}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: borzoDashboard,
  },
  entrega: {
    imgSrc: entrega,
    activated: true,
    key: "entrega",
    commercial_name: "entrega",
    country: ["MX"],
    image: (
      <img
        alt="entrega"
        src={entrega}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: entregaDashboard,
  },
  interrapidisimo: {
    imgSrc: interrapidisimoDashboard,
    activated: true,
    key: "interrapidisimo",
    commercial_name: "interrapidisimo",
    country: ["CO"],
    image: (
      <img
        alt="interrapidisimo"
        src={interrapidisimoDashboard}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: interrapidisimoDashboard,
  },
  treggo: {
    imgSrc: treggo,
    activated: true,
    key: "treggo",
    commercial_name: "treggo",
    country: ["MX"],
    image: (
      <img
        alt="treggo"
        src={treggo}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: treggoDashboard,
  },
  imile: {
    imgSrc: imile,
    activated: true,
    key: "imile",
    commercial_name: "iMile",
    country: ["MX"],
    image: (
      <img
        alt="imile"
        src={imile}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: imileDashboard,
  },
  tcc: {
    imgSrc: tcc,
    activated: true,
    key: "tcc",
    commercial_name: "TCC",
    country: ["CO"],
    image: (
      <img
        alt="tcc"
        src={tcc}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: tccDashboard,
  },
  vencedor: {
    imgSrc: vencedor,
    activated: true,
    key: "vencedor",
    commercial_name: "Vencedor",
    country: ["MX"],
    image: (
      <img
        alt="vencedor"
        src={vencedor}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: vencedorDashboard,
  },
  envia: {
    imgSrc: enviaco,
    activated: true,
    key: "envia",
    commercial_name: "Envia",
    country: ["CO"],
    image: (
      <img
        alt="envia"
        src={enviaco}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: enviacoDashboard,
  },
  test: {
    imgSrc: testDashboard,
    activated: true,
    key: "test",
    commercial_name: "test",
    country: ["CO", "MX"],
    image: (
      <img
        alt="test"
        src={testDashboard}
        style={{
          width: "auto",
          height: "75%",
          position: "absolute",
        }}
      />
    ),
    dashboardImageSrc: testDashboard,
  },
};
