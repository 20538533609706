import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { AdminDataContext } from "../../../context/adminContext";
import { usePost } from "../../../hooks/usePost";
import {
  getAllShipmentsByMonths,
  // getAllShipmentsByYears,
  AdminBodyRanges,
  AdminBodyRangesAndCountry,
  getAdminAnalytics,
  getInternationalShipmentsPerMonth,
  getNewRecordsByDay,
  getIntegrationsByTypeAndMonth,
  getTotalShipmentsAssuredToday,
} from "../../../services/admin_s";
import { FilterRequest } from "../../../services/user_d";
// import { useGet } from "../../../hooks/useGet";
import { Platforms } from "../../../interfaces/integrations.interface";
import { DashboardAnalyticsRes } from "../../../interfaces/admin.interface";

export const useSection2Dashboard = () => {
  const [registerDate, setRegisterDate] = useState<moment.Moment[]>([]);
  const [filter, setFilter] = useState<FilterRequest>({});
  const { country, platform, verify } = useContext(AdminDataContext);
  const [ranges, setRanges] = useState<AdminBodyRanges>({ //eslint-disable-line
    range1: new Date(moment().startOf("month").format("YYYY-MM-DD 00:00:00")),
    range2: new Date(moment().endOf("day").format("YYYY-MM-DD 23:59:59")),
  });
  const [months] = useState<AdminBodyRanges>({
    range1: new Date(moment().startOf("year").format("YYYY-MM-DD 00:00:00")),
    range2: new Date(moment().endOf("day").format("YYYY-MM-DD 23:59:59")),
  });

  const {
    fetchData,
    data: adminAnalytics,
    isLoading: isFetchingAdminAnalytics,
  } = usePost<FilterRequest, DashboardAnalyticsRes>(getAdminAnalytics);

  const {
    fetchData: fetchDataShipmentsByDateMonths,
    data: dataShiptmentsByDateMonth,
    isLoading: isLoadingShipmentsByDateMonth,
  } = usePost(getAllShipmentsByMonths);

  const {
    fetchData: fetchDataShipmentsAssurd,
    data: dataShiptmentsAssurd,
    isLoading: isLoadingShipmentsAssurd,
  } = usePost(getTotalShipmentsAssuredToday);

  const {
    fetchData: fetchInternationalShipments,
    data: dataInternationalShipments,
    isLoading: isLoadingInternationalShipments,
  } = usePost(getInternationalShipmentsPerMonth);

  // const {
  //   fetchData: fetchDataShipmentsByYears,
  //   data: dataShiptmentsByYears,
  //   isLoading: isLoadingShipmentsByYears,
  // } = usePost(getAllShipmentsByYears);

  const {
    fetchData: fetchIntegrationsByTypeAndMonth,
    data: dataIntegrationsByTypeAndMonth,
    isLoading: isLoadingIntegrationsByTypeAndMonth,
  } = usePost(getIntegrationsByTypeAndMonth);

  const {
    fetchData: fetchNewUsersType,
    data: dataNewUsersType,
    isLoading: isLoadingNewUsersType,
  } = usePost(getNewRecordsByDay);

  // const {data: verifyServices } = useGet(getVerifyServices())

  const isLoading = isFetchingAdminAnalytics;

  const getData = useCallback(
    async (filter: FilterRequest) => {
      await fetchData(filter);
    },
    [fetchData]
  );

  const getDataByDateMonths = useCallback(
    async (rangesAndCountry: AdminBodyRangesAndCountry) => {
      await fetchDataShipmentsByDateMonths(rangesAndCountry);
    },
    [fetchDataShipmentsByDateMonths]
  );

  const getDataByDateRange = useCallback(
    async (rangesAndCountry: AdminBodyRangesAndCountry) => {
      await fetchDataShipmentsAssurd(rangesAndCountry);
    },
    [fetchDataShipmentsAssurd]
  );

  const getInternationalShipments = useCallback(
    async (rangesAndCountry: AdminBodyRangesAndCountry) => {
      await fetchInternationalShipments(rangesAndCountry);
    },
    [fetchInternationalShipments]
  );

  // const getDataByYears = useCallback(
  //   async (country: AdminBodyCountry) => {
  //     await fetchDataShipmentsByYears(country);
  //   },
  //   [fetchDataShipmentsByYears]
  // );

  const getDataByIntegration = useCallback(
    async (plataform: Platforms) => {
      await fetchIntegrationsByTypeAndMonth(plataform);
    },
    [fetchIntegrationsByTypeAndMonth]
  );

  const getDataByNewUsers = useCallback(
    async (isVerified: string) => {
      await fetchNewUsersType(isVerified);
    },
    [fetchNewUsersType]
  );

  const filterByCreatedAt = useCallback(
    (e: any) => {
      setRegisterDate(e);
      if (!e) {
        delete filter.created_at;
      }

      const startDate = new Date(
        moment(e[0]._d).startOf("day").format("YYYY-MM-DD 00:00:00")
      );
      const endDate = new Date(
        moment(e[1]._d).endOf("day").format("YYYY-MM-DD 23:59:59")
      );

      setRanges({ range1: startDate, range2: endDate });
      setFilter({
        ...filter,
        created_at: {
          $gte: moment(e[0]._d).startOf("day").format("YYYY-MM-DD 00:00:00"),
          $lte: moment(e[1]._d).endOf("day").format("YYYY-MM-DD 23:59:59"),
        },
      });
    },
    [setFilter, setRegisterDate, filter]
  );
  useEffect(() => {
    const currentDate = moment();
    setRegisterDate([
      moment(currentDate).startOf("month"),
      moment(currentDate).endOf("day"),
    ]);
  }, []);

  useEffect(() => {
    getData(filter);
    getDataByDateMonths({
      ranges: { range1: months.range1, range2: months.range2 },
      country,
    });
    getInternationalShipments({
      ranges: { range1: months.range1, range2: months.range2 },
      country,
    });
    getDataByDateRange({
      ranges: { range1: ranges.range1, range2: ranges.range2 },
      country,
    })
    // getDataByYears({ country });
    getDataByIntegration(platform);
    getDataByNewUsers(verify);
  }, [country, platform, verify]); //eslint-disable-line

  return {
    adminAnalytics,
    isLoading,
    registerDate,
    filterByCreatedAt,
    dataShiptmentsByDateMonth,
    isLoadingShipmentsByDateMonth,
    dataInternationalShipments,
    isLoadingInternationalShipments,
    dataIntegrationsByTypeAndMonth,
    dataNewUsersType,
    getDataByIntegration,
    getDataByNewUsers,
    isLoadingIntegrationsByTypeAndMonth,
    isLoadingNewUsersType,
    dataShiptmentsAssurd,
    isLoadingShipmentsAssurd,
    fetchDataShipmentsByDateMonths
  };
};
