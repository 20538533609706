import { Col, notification, Row, Typography } from "antd"
// import UserHeader from "../Components/UserHeader";
import { UserItemI } from "../Search/SearchItem";
import { FC, useEffect, useState } from "react";
import SectionLayout from "./SectionLayout";
import { dataToShowI, UserAccessI, userActionsT, VerificationsI } from "../interfaces";
import VerificationCard from "../Components/Verifications/VerificationCard";
import usePermission from "../../../../hooks/usePermission";
import { ActionsSeachUsers, PermissionViews } from "../../../../interfaces/permissions.interfaces";
import { usePatch } from "../../../../hooks/usePatch";
import { updateVerifications } from "../../../../services/user_d";
import useDevice from "../../../../hooks/useDevice";

interface props {
    userFound: UserItemI;
    fullUser: dataToShowI | null;
    refetch: (user: string) => void;
    changeActions: (type: userActionsT) => void;
    showDrawer: () => void;
}

interface Styles {
    title: React.CSSProperties;
    titleMobile: React.CSSProperties;
    container: React.CSSProperties;
    cardsList: React.CSSProperties;
    cardsListMobile: React.CSSProperties;

}

const styles: Styles = {
    title: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    titleMobile: {
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    container: {
        width: '100%'
    },
    cardsList: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '1rem',
        margin: '2rem 0'
    },
    cardsListMobile: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        gap: '1rem',
        margin: '2rem 0'
    }

}

const VerificationsPage: FC<props> = ({ userFound, fullUser, changeActions, refetch, showDrawer }) => {
    const { hasAccess } = usePermission();
    const { isTablet } = useDevice()
    const { fetchDataPatch } = usePatch(updateVerifications)
    const [verifications, setVerifications] = useState<VerificationsI | null>(null)
    const [UserAccess] = useState<UserAccessI>({
        verify: hasAccess(
            ActionsSeachUsers.verify,
            PermissionViews.search_users
        ),
        recharge: hasAccess(
            ActionsSeachUsers.recharge,
            PermissionViews.search_users
        ),
        discount: hasAccess(
            ActionsSeachUsers.discount,
            PermissionViews.search_users
        ),
        update: hasAccess(
            ActionsSeachUsers.update,
            PermissionViews.search_users
        ),
        block_users: hasAccess(
            ActionsSeachUsers.block_users,
            PermissionViews.search_users
        ),
        admin: hasAccess(
            ActionsSeachUsers.assign_admin,
            PermissionViews.search_users
        ),
        special_prices: hasAccess(
            ActionsSeachUsers.user_special_prices,
            PermissionViews.search_users
        ),
        vip: hasAccess(ActionsSeachUsers.update, PermissionViews.search_users),
        special_accounts: hasAccess(ActionsSeachUsers.update, PermissionViews.search_users),
        estafeta_special: hasAccess(ActionsSeachUsers.update, PermissionViews.search_users),
        carriersActive: hasAccess(
            ActionsSeachUsers.update,
            PermissionViews.search_users
        ),
    });

    useEffect(() => {
        setVerifications({
            international_senders: fullUser?.security.international_senders || false,
            identity_verified_by_document: fullUser?.security.identity_verified_by_document || false,
            vip: fullUser?.security.vip || false,
            identity_verified: fullUser?.security.identity_verified || false,
            senders_verified: fullUser?.security.senders_verified || false,
            special_accounts: fullUser?.security.special_accounts || false,
            estafeta_special: fullUser?.security.estafeta_special || false,
            reseller: fullUser?.security.reseller || false,
        })
    }, []) //eslint-disable-line

    const onChange = async (event: any, key: string) => {
        setVerifications({
            ...verifications,
            [key]: event
        })
        await onUpdateVerification({
            [key]: event
        })
    }

    const onUpdateVerification = async (values: VerificationsI) => {
        try {
            const updateVerifications = await fetchDataPatch(
                fullUser?.user as string,
                values
            )
            if (updateVerifications) {
                notification.success({
                    message: 'La verificación se ha realizado',
                });
                refetch(fullUser?.user as string)
            } else {
                notification.error({
                    message: 'La verificación ha fallado',
                });
            }
        } catch (error) {
            console.log(error)
            setVerifications({
                ...verifications,
                [`${Object.keys(values)[0]}`]: !Object.values(values)[0]

            })
            notification.error({
                message: 'La verificación ha fallado',
            });
        }
    }

    return (
        <SectionLayout 
            title="Verificaciones" 
            userFound={userFound} 
            changeActions={changeActions} 
            showDrawer={showDrawer} 
            roles={fullUser?.roles || []} >
            <Row>
                <Col span={24}>
                    <Typography style={isTablet ? styles.titleMobile : styles.title}>
                        Verificaciones manuales
                    </Typography>
                </Col>
                <Row gutter={20} justify="space-around" wrap style={isTablet ? styles.cardsListMobile : styles.cardsList}>
                    <VerificationCard
                        label="Verificación"
                        access={UserAccess.verify}
                        checked={verifications?.identity_verified as boolean}
                        onChange={(event) => onChange(event, 'identity_verified')}
                    />
                    <VerificationCard
                        label="Remitentes"
                        access={UserAccess.verify}
                        checked={verifications?.senders_verified as boolean}
                        onChange={(event) => onChange(event, 'senders_verified')}
                    />
                    <VerificationCard
                        label="Verificación INE"
                        access={UserAccess.verify}
                        checked={verifications?.identity_verified_by_document as boolean}
                        onChange={(event) => onChange(event, 'identity_verified_by_document')}
                    />
                    <VerificationCard
                        label="Envíos internacionales"
                        access={UserAccess.verify}
                        checked={verifications?.international_senders as boolean}
                        onChange={(event) => onChange(event, 'international_senders')}
                    />
                    <VerificationCard
                        label="Vip"
                        access={UserAccess.vip}
                        checked={verifications?.vip as boolean}
                        onChange={(event) => onChange(event, 'vip')}
                    />
                    <VerificationCard
                        label="DHL especial"
                        access={UserAccess.special_accounts}
                        checked={verifications?.special_accounts as boolean}
                        onChange={(event) => onChange(event, 'special_accounts')}
                    />
                    <VerificationCard
                        label="Estafeta especial"
                        access={UserAccess.estafeta_special}
                        checked={verifications?.estafeta_special as boolean}
                        onChange={(event) => onChange(event, 'estafeta_special')}
                    />
                    <VerificationCard
                        label="Revendedor"
                        access={UserAccess.verify}
                        checked={verifications?.reseller as boolean}
                        onChange={(event) => onChange(event, 'reseller')}
                    />
                </Row>
                <Col span={24}>
                    <Typography style={isTablet ? styles.titleMobile : styles.title}>
                        Verificaciones automáticas
                    </Typography>
                </Col>
                <Row gutter={20} justify="space-around" wrap style={isTablet ? styles.cardsListMobile : styles.cardsList}>
                    <VerificationCard
                        label="Verificación OTP"
                        access={UserAccess.verify}
                        viewOnly={true}
                        checked={fullUser?.metadata.activity.OTP}
                        onChange={() => { }}
                    />
                    <VerificationCard
                        label="Envíos express"
                        access={UserAccess.verify}
                        viewOnly={true}
                        optional={fullUser?.security.isBlocked ? false : true}
                        checked={fullUser?.security?.express_shipments_verified || false}
                        onChange={() => { }}
                    />
                    <VerificationCard
                        label="Envíos terrestres"
                        access={UserAccess.verify}
                        viewOnly={true}
                        optional={fullUser?.security.isBlocked ? false : true}
                        checked={fullUser?.security?.ground_shipments_verified || false}
                        onChange={() => { }}
                    />
                    <VerificationCard
                        label="Envíos multipaquete"
                        access={UserAccess.verify}
                        viewOnly={true}
                        optional={fullUser?.security.isBlocked ? false : true}
                        checked={fullUser?.security?.multipackage_shipments_verified || false}
                        onChange={() => { }}
                    />
                    <VerificationCard
                        label="Registro completo"
                        access={UserAccess.verify}
                        viewOnly={true}
                        checked={fullUser?.metadata.activity.completedRegister || false}
                        onChange={() => { }}
                    />
                    <VerificationCard
                        label="Perfil completo"
                        access={UserAccess.verify}
                        viewOnly={true}
                        checked={fullUser?.metadata.activity.completedProfile || false}
                        onChange={() => { }}
                    />
                    <VerificationCard
                        label="Primera recarga"
                        access={UserAccess.verify}
                        viewOnly={true}
                        checked={fullUser?.metadata.activity.firstRechange || false}
                        onChange={() => { }}
                    />
                    <VerificationCard
                        label="ID verificación INE"
                        access={UserAccess.verify}
                        viewOnly={true}
                        checked={false}
                        optional={true}
                        text={fullUser?.security.identity_document_id || 'N/A'}
                        onChange={() => { }}
                    />
                </Row>
            </Row>
            <Row>
            </Row>

        </SectionLayout>
    )
}

export default VerificationsPage