import { t } from 'i18next'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { ApiContext } from '../../../../context/apiContext'
import { AdminDataContext } from '../../../../context/adminContext'
import { TableV3 } from '../../../../components/Common/TableV3'
import { CancelStatus } from '../../../../components/Common/CancelStatus'
import { getParcelImage, linkRastreo } from '../../../../services/records'
import { filterByColumnsContructor, GetAllFilteredQuery } from '../../../../interfaces/table.interface'
import { ActionsRegisters } from '../../../../interfaces/permissions.interfaces'
import { AppContext } from '../../../../context/appContext'
import { Pickup, PickupStatus } from '../../../../interfaces/pickups.interface'
import { Actions as RecollectionsActions } from './Actions'
import { DetailsActions } from './DetailsAction'

const filterByColumnsFactory = ({
	country,
	currentPage = 1,
	rowsPerPages = 20, 
	searchText = ''
}: filterByColumnsContructor) => ({ 
	country,
	searched: searchText,
	range: { from: currentPage, to: rowsPerPages },
	columns: [
    { id: 'created_at', find: false },
    { id: 'user', find: false },
    { id: 'pickup_date', find: false },
    { id: 'service_id', find: false },
    { id: 'guide', find: false },
    { id: 'carrier', find: false },
    { id: 'folio', find: false },
    { id: 'address', find: false },
    {
      id: 'pickups_status',
      find: true,
      filters: {
        sort: false,
        filter: true,
        filter_values: ['Agendado']
      }
    }
  ]
})

const RecollectionsV2 = () => {
  const { t } = useTranslation()
  const { userToken, backendURL } = useContext(ApiContext)
  const { userData } = useContext(AppContext);
  const { country } = useContext(AdminDataContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPages, setRowPerPages] = useState(20)
  const [total, setTotal] = useState(0)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [dataColumns, setDataColumns] = useState<any>(null)
  const [filterByColumns, setFilterByColumns] = useState<GetAllFilteredQuery>(filterByColumnsFactory({ country, currentPage, rowsPerPages, searchText }))
	const [initRender, setInitRender] = useState(false)

  const recollectionsData = (item: any, userData: any) => {
    const parceText = item?.service_id.split("_mx_" || "_co_")
    return {
      guide: item.tracking ? item.tracking : "No hay guía registrada",
      user: item.email ? item.email : "Usuario no registrado",
      carrier: item.carrier,
      service_id: userData.metadata?.permissions?.registers?.actions?.includes(ActionsRegisters.provider_info)? parceText[1]  : item.carrier,
      created_at: new Date(item.date_from).toLocaleDateString(),
      pickup_date: new Date(item.date_to).toLocaleDateString(),
      folio: item.folio || "Folio No Disponible",
      pickups_status: item.pickups_status,
      address: `${item.address ? item.address.origin.street : ""} #${
        item.address ? item.address.origin.number : ""
      } Col. ${item.address ? item.address.origin.district : ""}`,
      data: {
        ...item,
        address: [ item.address ]
      },
    }
  }

  const handleFecth = async (filterByColumns: GetAllFilteredQuery, saveState = true) => {
    const { data } = await axios.post(
        `${backendURL}users/pickups/getAllRecollectionsV2`, 
        filterByColumns, 
        { headers: { Authorization: `Bearer ${userToken}` }}
    )
    
    setTotal(data?.total)
    const formatedData = data?.data?.map((item: any)=>  recollectionsData(item,userData))

    if (saveState) {
      setDataColumns(formatedData)
    } else {
      return formatedData ?? []
    }
  }

  const handleStateFilterByColumns = ({ user, searchText, country, currentPage = 1, rowsPerPages }: filterByColumnsContructor) => {	
		const customFilterByColumns = { 
		  	...filterByColumns,
		}
	
		if (user) customFilterByColumns.user = user	
		if (country) customFilterByColumns.country = country
		if (searchText !== undefined) customFilterByColumns.searched = searchText
		
		if (currentPage){
			setCurrentPage(currentPage)
			customFilterByColumns.range.from = currentPage 
		}
	
		if (rowsPerPages){
			setRowPerPages(rowsPerPages)
			customFilterByColumns.range.to = rowsPerPages 
		}
	
		return customFilterByColumns
	}

	useEffect(() => {
		if (!initRender){
			(async () => {
				setIsRefetching(true)
				await handleFecth(filterByColumns)
				setIsRefetching(false)
				setInitRender(true)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (initRender){
			(async () => {
				setIsRefetching(true)
				await handleFecth(filterByColumns)
				setIsRefetching(false)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [filterByColumns]) 
	
  useEffect(() => {
    if (initRender){
      (async () => {
        setCurrentPage(1)
        const customFilters = filterByColumnsFactory({ country, currentPage: 1, rowsPerPages })
        setFilterByColumns(customFilters)
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  return (
    <div>
      {' '}
      <TableV3
        title={t('Pickups.Table.Title')}
        tableType="pickups"
        isShipments
        data={dataColumns ?? []}
        total={total}
        isLoading={refetching}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(row) => setRowPerPages(row)}
        schemaDownload={RecollectionsDownloads}
        columns={
          RecollectionsColumnV2(
            async ()=> await handleFecth(filterByColumns),
            dataColumns ?? [],
          ) as ColumnsType<any>
        }
        stateValues={{
          country,
          currentPage,
          rowsPerPages,
          searchText,
          filterByColumns,
        }}
        filters={{
          filterBySearchText(text: string) {
            setSearchText(text)
          },
          filterByColumns(filter: GetAllFilteredQuery) {
            setFilterByColumns(filter)
          },
          filterDownloads: async (filter: GetAllFilteredQuery) => {
            filter.isDownload = true
            return await handleFecth(filter, false)
          },
          handleStateFilterByColumns: (props: filterByColumnsContructor) => {
						return handleStateFilterByColumns(props)
					},
					filterByColumnsFactory: (props: filterByColumnsContructor)=> {
						return filterByColumnsFactory(props)
					}
        }}
      />
    </div>
  )
}

const RecollectionsColumnV2 = (
  refetch: ()=> void,
  data: Pickup[],
): ColumnsType<any>=> {
  const uniqueCarriers = Array.from(new Set(data.map((item) => item.carrier)))
  const uniquePickupStatus = Array.from(new Set(data.map((item) => item.pickups_status))) as PickupStatus[]

  const statusMapUI = {
    Agendado: t('Pickups.Table.Columns.Status.Scheduled'),
    Solicitada: t('Pickups.Table.Columns.Status.Solicited'),
    'En espera': t('Pickups.Table.Columns.Status.Solicited'),
    'Cancelada de prov': t('Pickups.Table.Columns.Status.CancelledFromProvider'),
    'Sin disponibilidad': t('Pickups.Table.Columns.Status.Exception'),
  }

  const transformedPickupStauts: Record<PickupStatus, string> = {
    Agendado: "Activa",
    "Cancelada de prov": "Cancelada de prov",
    Solicitada: "Solicitada",
    "En espera": "En espera",
    "Sin disponibilidad": "Sin disponibilidad",
  };

  return [
    {
      title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Pickups.Table.Columns.Date')}</Tooltip>,
      dataIndex: 'created_at',
      align: 'center',
      showSorterTooltip: false,
      sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (createdAt: string) => new Date(createdAt).toLocaleString()
    },
    {
      title: t('Pickups.Table.Columns.User'),
      dataIndex: 'user',
      align: 'center'
    },
    {
      title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Pickups.Table.Columns.PickupDate')}</Tooltip>,
      dataIndex: 'pickup_date',
      align: 'center',
      showSorterTooltip: false,
      sorter: (a, b) => new Date(a.pickup_date).getTime() - new Date(b.pickup_date).getTime(),
      render: (pickup_date: string) => new Date(pickup_date).toLocaleString()
    },
    {
      title: t('Pickups.Table.Columns.ServiceId'),
      dataIndex: 'service_id',
      align: 'center'
    },
    {
      title: t('Pickups.Table.Columns.Guide'),
      dataIndex: '',
      align: 'center',
      render: (rowData: any) => (
        <a href={linkRastreo(rowData.carrier, rowData.guide)} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
          {rowData.guide}
        </a>
      )
    },
    {
      title: t('Pickups.Table.Columns.Carrier'),
      dataIndex: 'carrier',
      align: 'center',
      filters: uniqueCarriers.map((carrier) => ({ text: carrier, value: carrier })),
      onFilter: (value, record) => record.carrier.indexOf(value as string) === 0,
      render: (carrier) => getParcelImage(carrier)
    },
    {
      title: t('Pickups.Table.Columns.Folio'),
      dataIndex: 'folio',
      align: 'center'
    },
    {
      title: t('Pickups.Table.Columns.Address'),
      dataIndex: 'address',
      align: 'center'
    },
    {
      title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Pickups.Table.Columns.Status')}</Tooltip>,
      dataIndex: 'pickups_status',
      align: 'center',
      showSorterTooltip: false,
      sorter: (a, b) => a.pickups_status.localeCompare(b.pickups_status),
      filters: uniquePickupStatus.map((status) => ({ text: statusMapUI[status], value: status })),
      onFilter: (value, record) => record.pickups_status.indexOf(value as string) === 0,
      render: (pickups_status) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CancelStatus
            status={transformedPickupStauts[pickups_status as PickupStatus]}
            customText={pickups_status}
            withIcon={false}
          />
        </div>
      )
    },
    {
      title: t('Pickups.Table.Columns.Actions'),
      dataIndex: '',
      align: 'center',
      render: (rowData: any) => (
        <RecollectionsActions refetch={refetch} data={rowData.data} />
      ),
    },
    {
      title: t('Pickups.Table.Columns.Details'),
      dataIndex: '',
      align: 'center',
      render: (rowData: any) => (
        <DetailsActions refetch={refetch} data={rowData.data} />
      ),
    },
  ]
}

const RecollectionsDownloads = (data: any[])=> {
  const statusMapUI = {
    Agendado: "Activa",
    "Cancelada de prov": "Cancelada de prov",
    Solicitada: "Solicitada",
    "En espera": "En espera",
    "Sin disponibilidad": "Sin disponibilidad",
  }

  return data.map(item => ({
      'Fecha de solicitud': item?.created_at,
      Usuario: item?.user,
      'Fecha de recoleccion': item?.pickup_date,
      'Id de Prooveedor': item?.service_id,
      'Guía': item?.guide,
      'Paqueteria': item?.carrier,
      'Folio': item?.folio,
      'Dirección de Recolección': item?.address,
      'Status': statusMapUI[item?.pickups_status as PickupStatus],
  }))
}

export default RecollectionsV2
