import React, { CSSProperties } from 'react';
//import DownloadButtons from '../../utils/DownloadCsv/DownloadButtons'
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { Sticky, StickyContainer } from 'react-sticky';

import '../../index.less';
//import DownloadCsv from '../../utils/DownloadCsv/DownloadCsv'

interface tabProps {
	component: React.ReactElement;
	description?: string;
}
export interface tabObject {
	[key: string]: tabProps;
}

interface tabsContainerProps {
	titlesAndContent: tabObject;
	defaultTab?: number | undefined;
	onChange?: (data: any) => void | undefined;
	direction?: 'vertical' | 'horizontal' | undefined;
	style?: CSSProperties | undefined;
}

const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
	<Sticky bottomOffset={80}>
		{({ style }) => (
			<DefaultTabBar
				{...props}
				className="site-custom-tab-bar"
				style={{ ...style }}
			/>
		)}
	</Sticky>
);

const TabsContainer: React.FC<tabsContainerProps> = ({
	titlesAndContent,
	defaultTab,
	onChange,
	direction,
	style,
}) => {
	const { TabPane } = Tabs;

	return (
		<section className='dashboardSection'>
			<StickyContainer>
				<Tabs
					defaultActiveKey={defaultTab ? `${defaultTab}` : '1'}
					onChange={onChange}
					style={{
						width: '100%',
						height: 'fit-content',
						overflow: 'hidden',
						padding: '30px',
						borderRadius: '20px',
						backgroundColor: 'white',
						boxShadow: '4px 2px 14px rgba(45, 27, 110, 0.13)',
						...style,
					}}
					tabPosition={direction === 'vertical' ? 'left' : 'top'}
					renderTabBar={renderTabBar}
				>
					{Object.entries(titlesAndContent).map((entry, index) => {
						const [key, value] = entry;
					
						return (
							<TabPane
								tab={key}
								key={`${key}${value.description ? `|${value.description}` : ''}`}
							>
								{value.component}
							</TabPane>
						);
					})}
				</Tabs>
			</StickyContainer>
		</section>
	);
};

export default TabsContainer;
