import { Col, Row, Switch, notification } from "antd";
import CardContainer from "../../../../components/Containers/CardContainer";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { useState, useEffect } from "react";
import { updateProviderStatusService } from "../../../../services/userbalance_d";
import { usePost } from "../../../../hooks/usePost";

type Props = {
  filteredData: any;
  isLoading: boolean;
};

export const VerifyServices = ({ filteredData, isLoading }: Props) => {
  const serviceStatus = {
    globalpaq: "A-P09",
    envia: "A-P01",
    shipandGo: "A-P04",
    pack2go: "A-P03",
    shippo: "A-P06",
    nextshipping: "A-P07",
    ziyego: "A-P11",
    t1envios: "A-P10",
    autopaquete: "A-P12",
  };

  const { fetchData: fetchUpdateProvider } = usePost(updateProviderStatusService);
  const [dataToShow, setDataToShow] = useState<{
    [provider: string]: boolean;
  }>(() => {
    const storedData = localStorage.getItem("providerSwitches");
    return storedData ? JSON.parse(storedData) : {
      globalpaq: true,
      envia: true,
      shipandGo: true,
      pack2go: true,
      shippo: true,
      nextshipping: true,
      ziyego: true,
      t1envios: true,
      autopaquete: true,
    };
  });

  useEffect(() => {
    localStorage.setItem("providerSwitches", JSON.stringify(dataToShow));
  }, [dataToShow]);

  const updateProviderActive = async (
    provider: string,
    isActive: boolean
  ) => {
    const newDataToShow = { ...dataToShow, [provider]: isActive };
    setDataToShow(newDataToShow);

    console.log('Datos enviados al backend:', newDataToShow);

    const resp = await fetchUpdateProvider({
      provider: provider,
      isActive: isActive,
    });

    if (resp.error) {
      setDataToShow((prev) => ({ ...prev, [provider]: !isActive }));
      notification.error({
        message: "No se pudo actualizar el proveedor",
      });
    }
  };

  return (
    <CardContainer
      cardStyle={{ marginTop: "20px" }}
      titleStyle={{ placeItems: 'center' }}
      title="Proveedores disponibles"
    >
      <hr />
      <Row>
        <Col span={16} style={{ margin: "auto", fontWeight: "bold", textAlign: "center" }}>
          <p>Proveedores</p>
        </Col>
        <Col span={8} style={{ margin: "auto", fontWeight: "bold" }}>
          <p>Estado</p>
        </Col>
      </Row>
      <Col span={24} style={{ maxHeight: "25vh", overflowY: "auto" }}>
        {isLoading ? (
          <LoadingAnimation animationType="small" />
        ) : (
          <>
            {Object.entries(serviceStatus).map(([serviceName, label]) => (
              <Row key={serviceName}>
                <Col span={16} style={{ margin: "5px auto", textAlign: "center" }}>
                  {label}
                </Col>
                <Col span={8} style={{ margin: "5px auto" }}>
                  <Switch
                    checked={dataToShow[serviceName]}
                    onChange={async () => {
                      await updateProviderActive(serviceName, !dataToShow[serviceName]);
                    }}
                  />
                </Col>
              </Row>
            ))}
          </>
        )}
      </Col>
      {/* {Object.keys(filteredData?.VerifyServices || {}).length === 0 && (
        <p
          style={{
            fontWeight: "bold",
            margin: "50px auto",
            color: "#0008",
          }}
        >
          No hay datos para mostrar.
        </p>
      )} */}
    </CardContainer>
  );
};