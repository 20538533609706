import { useState, useEffect, useContext } from "react";
import Chart from "react-apexcharts";
import CardContainer from "../../../../components/Containers/CardContainer";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { Select } from "antd";
import { AppContext } from "../../../../context/appContext";
import { t } from "i18next";

type Props = {
	filteredData: any;
	isLoading: boolean;
	refetchData: any;
};

const getServiceName = (service: string) => {
	switch (service) {
		case "ground":
			return "Terrestre";
		case "express":
			return "Express";
		case "metropolitano":
			return "Metropolitano";
		case "n_especial":
			return "Dia siguiente Especial";
		case "ground_without_pickup":
			return "Terrestre sin recolección";
		case "g_especial":
			return "Terrestre especial";
		case "economic":
			return "Económico";
		case "next_day":
			return "Día siguiente";
		case "saver":
			return "Saver";
		case "especial":
			return "Especial";
		case "priority_express":
			return "Express Prioritario";
		case "estandard":
		case "standard":
			return "Estándar";
		case "2_days":
			return "2 días";
		case "domestic_12-00":
			return "Domestico 12:00";
		case "priority":
			return "Prioritario";
		case "priority_10-30":
			return "Prioritario 10:30";
		case "domestic_easy_doc":
			return "Domestico Easy Doc";
		case "domestic_10-30":
			return "Domestico 10:30";
		case "expedited":
			return "Expedited";
		case "bulto":
			return "Bulto";
	}
};

const servicesArray = [
	"ground",
	"express",
	"metropolitano",
	"n_especial",
	"ground_without_pickup",
	"g_especial",
	"economic",
	"next_day",
	"saver",
	"especial",
	"priority_express",
	"estandard",
	"standard",
	"2_days",
	"domestic_12-00",
	"priority",
	"priority_10-30",
	"domestic_easy_doc",
	"domestic_10-30",
	"expedited",
	"bulto",
];

const carriersArray = [
	"fedex",
	"dhl",
	"estafeta",
	"redpack",
	"ups",
	"paquetexpress",
	"vencedor",
	"ampm",
	"imile",
	"tresguerras",
	"carssa",
	"noventa9minutos",
	"redservi",
];

const GraphDailyByService = ({ isLoading, filteredData, refetchData }: Props) => {
	console.log("🚀 ~ GraphDailyByService ~ filteredData:", filteredData);
	const [categories, setCategories] = useState<string[]>([]);
	const [data, setData] = useState<number[]>([]);
	console.log("🚀 ~ GraphDailyByService ~ data:", data);
	const [selectorValue, setSelectorValue] = useState<string>("fedex");
	const [selectorService, setSelectorService] = useState<string>("ground");
	const { userData } = useContext(AppContext);
	const { Option } = Select;

	useEffect(() => {
		const handleData = async () => {
			const rd = await refetchData({ carrier: selectorValue, service: selectorService });
      if (rd.data.res.length === 0 || rd.data.res === undefined) {
        setCategories([]);
        setData([]);
        return;
      }
			const days: string[] = rd.data.res?.map((item: any) => {
				const date = new Date(item.date);
				return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
			});

			setCategories(days);
			setData(rd.data.res.map((item: any) => item.count));
		};

		handleData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectorValue, selectorService]);

	const handleSelectorChange = (selectedValue: string) => {
		setSelectorValue(selectedValue);
	};

	const options = {
		chart: {
			id: `day-provider-bar`,
		},
		xaxis: {
			categories,
		},
	};

	const series = [
		{
			name: "series",
			data,
		},
	];

	return (
		<>
			{(userData?.roles[0] === "ADMIN" || userData?.roles[0] === "DEV") && (
				<CardContainer
					cardStyle={{ marginTop: "20px", textAlign: "center" }}
					title="Envios diarios por servicio"
					titleStyle={{ placeItems: "center" }}
				>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Select
							value={selectorValue}
							onChange={handleSelectorChange}
							style={{ width: "100%", textAlign: "center" }}
						>
							<Option value="option1" style={{ textAlign: "center" }}>
								General
							</Option>
							{carriersArray.map((carrier) => (
								<Option key={carrier} value={carrier} style={{ textAlign: "center" }}>
									{t(`Quote.Service.Carrier.${carrier}`)}
								</Option>
							))}
						</Select>
						<Select
							value={selectorService}
							onChange={setSelectorService}
							style={{ width: "100%", textAlign: "center" }}
						>
							<Option value="option1" style={{ textAlign: "center" }}>
								Servicio
							</Option>
							{servicesArray.map((carrier) => (
								<Option key={carrier} value={carrier} style={{ textAlign: "center" }}>
									{getServiceName(carrier)}
								</Option>
							))}
						</Select>
					</div>
					{isLoading ? (
						<LoadingAnimation animationType="small" />
					) : (
						<Chart options={options} series={series} type="area" width="100%" />
					)}
				</CardContainer>
			)}
		</>
	);
};

export default GraphDailyByService;
