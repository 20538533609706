import axios, { AxiosResponse } from 'axios'
import { useContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { TableV3 } from '../../../../components/Common/TableV3'
import { AllColumnsType, PendingCommissions as PendingCommission } from '../../../../interfaces/partner.interface'
import { PendingCommissionsDownloads } from './schemaDownloads'
import { PendingCommissionsColumn } from './schemaColumns'
import { AdminDataContext } from '../../../../context/adminContext'
import { filterByColumnsContructor, GetAllFilteredQuery } from '../../../../interfaces/table.interface'

const backendUrlPartner = process.env.REACT_APP_PARTNERS_BACKEND_URL

const filterByColumnsFactory = ({
	country,
	currentPage = 1,
	rowsPerPages = 20, 
	searchText = ''
}: filterByColumnsContructor) => ({
	country,
	searched: searchText,
	range: { from: currentPage, to: rowsPerPages },
	columns: [
    { id: 'createdAt', find: false },
    { id: 'owner', find: false },
    { id: 'email', find: false },
    { id: 'shipments', find: false },
    { id: 'amount', find: false },
  ]
})

const PendingCommissions = () => {
  const { t } = useTranslation()
  const { getAccessTokenSilently } = useAuth0()
  const { country } = useContext(AdminDataContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPages, setRowPerPages] = useState(20)
  const [total, setTotal] = useState(0)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [dataColumns, setDataColumns] = useState<any>(null)
  const [filterByColumns, setFilterByColumns] = useState<GetAllFilteredQuery>(filterByColumnsFactory({ country, currentPage, rowsPerPages, searchText }))
  const [initRender, setInitRender] = useState(false)

  const handleFecth = async (filterByColumns: GetAllFilteredQuery, saveState = true) => {
    const userToken = await getAccessTokenSilently()
    const { data }: AxiosResponse<{ total: number, data: PendingCommission[]}> = await axios.post(
      `${backendUrlPartner}/admin/withdrawals/table`, 
      filterByColumns, 
      { headers: { Authorization: `Bearer ${userToken}` }}
    )

    setTotal(data?.total)
    const formatedData = data?.data
      .filter((item) => item.referral.shipments.length !== 0)
      .map((data) => {
        const amountBase = data.referral.shipments.reduce((total: Number, shipment: any)=> {
          return total + shipment.price
        }, 0)

        const amountTotal = ((amountBase as number) * 3) / 100
        
        return {
            createdAt: data.createdAt,
            referral: {
                email: data.referral.email,
                amount: amountTotal,
                shipments: data.referral.shipments,
            },
            profile: {
                email: data.profile.email
            }
        }
      })
      .sort((item, item2) => item2.referral.amount - item.referral.amount)

    if (saveState){
      setDataColumns(formatedData)
    } else {
      return formatedData ?? []
    }
  }

  const handleStateFilterByColumns = ({ user, searchText, country, currentPage = 1, rowsPerPages }: filterByColumnsContructor) => {	
		const customFilterByColumns = { 
		  	...filterByColumns,
		}
	
		if (user) customFilterByColumns.user = user	
		if (country) customFilterByColumns.country = country
		if (searchText !== undefined) customFilterByColumns.searched = searchText
		
		if (currentPage){
			setCurrentPage(currentPage)
			customFilterByColumns.range.from = currentPage 
		}
	
		if (rowsPerPages){
			setRowPerPages(rowsPerPages)
			customFilterByColumns.range.to = rowsPerPages 
		}
	
		return customFilterByColumns
	}

  useEffect(() => {
		if (!initRender){
			(async () => {
				setIsRefetching(true)
				await handleFecth(filterByColumns)
				setIsRefetching(false)
				setInitRender(true)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (initRender){
			(async () => {
				setIsRefetching(true)
				await handleFecth(filterByColumns)
				setIsRefetching(false)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByColumns]) 
	
  useEffect(() => {
    if (initRender){
      (async () => {
        setCurrentPage(1)
        const customFilters = filterByColumnsFactory({ country, currentPage: 1, rowsPerPages })
        setFilterByColumns(customFilters)
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  return (
    <div>
      {' '}
      <TableV3
        title={t('PendingCommissions.Table.Title')}
        tableType="commissions"
        data={dataColumns ?? []}
        total={total}
        isLoading={refetching}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(row) => setRowPerPages(row)}
        schemaDownload={PendingCommissionsDownloads}
        columns={PendingCommissionsColumn(dataColumns ?? []) as ColumnsType<AllColumnsType>}
        config={{ disableFilters: true }}
        stateValues={{
          country,
          currentPage,
          rowsPerPages,
          searchText,
          filterByColumns,
        }}
        filters={{
          filterBySearchText(text: string) {
            setSearchText(text)
          },
          filterByColumns(filter: GetAllFilteredQuery) {
            setFilterByColumns(filter)
          },
          filterDownloads: async (filter: GetAllFilteredQuery) => {
            return await handleFecth(filter, false)
          },
          handleStateFilterByColumns: (props: filterByColumnsContructor) => {
						return handleStateFilterByColumns(props)
					},
					filterByColumnsFactory: (props: filterByColumnsContructor)=> {
						return filterByColumnsFactory(props)
					},
        }}
      />
    </div>
  )
}

export default PendingCommissions