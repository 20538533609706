import React from "react";
import { Col, Row } from "antd";
import Graph from "./Graph";
import MostUsedPackagesDaily from "./MostUsedPackagesDaily";
import { MostUsedPackages } from "./MostUsedPackages";
import { VerifyServices } from "./VerifyServices";
import { VerifyPaqueterias } from "./VerifyPaqueterias";
import { ShipmentsUserTopDay } from "./ShipmentsUserTopDay";
import { ShipmentsUserTop } from "./ShipmentsUserTop";
import { ShipmentsToday } from "./ShipmentsToday";
import { TopCards } from "./TopCards";
import { ShipmentsMonth } from "./ShipmentsMonth";
import { useSection1Dashboard } from "../../hooks/useSection1Dashboard";
import { MultipackageDaily } from "./MultiPackageDaily";
import { NewUsersShipments } from "./NewUsersShipsments";
import { BlockedUsersDaily } from "./BlockedUsersDaily";
import GraphDailyByService from "./GraphDailyByService";

export const UsersAnalytics: React.FC = () => {
	const {
		adminAnalytics,
		country,
		isLoading,
		isLoadingShipmentsByDateRange,
		getShipmentsTodaySupp,
		dataShiptmentsByDateRange,
		getDailyShipmentsService,
		refetchGetDailyShipmentsByService,
		isLoadingGetDailyShipmentsByService
	} = useSection1Dashboard();

	return (
		<>
			<TopCards filteredData={adminAnalytics!} isLoading={isLoading} country={country} />
			<Row gutter={[10, 10]}>
				{country === "MX" && (
					<>
						<Col xs={24} sm={24} md={12} lg={8}>
							<NewUsersShipments filteredData={adminAnalytics!} isLoading={isLoading} />
						</Col>
						<Col xs={24} sm={24} md={24} lg={16}>
							<MultipackageDaily country={country} />
						</Col>
						<Col xs={24} sm={24} md={12} lg={8}></Col>
						<Col xs={24} sm={24} md={24} lg={16}>
							<BlockedUsersDaily country={country} />
						</Col>
					</>
				)}
				<Col xs={24} sm={24} md={12} lg={8}>
					<ShipmentsMonth country={country} />
				</Col>
				<Col xs={24} sm={24} md={12} lg={8}>
					<MostUsedPackages country={country} />
				</Col>
				<Col xs={24} sm={24} md={12} lg={8}>
					<ShipmentsUserTop country={country} />
				</Col>
				<Col xs={24} sm={24} md={12} lg={8}>
					<ShipmentsToday filteredData={getShipmentsTodaySupp} isLoading={isLoading} />
				</Col>
				{country === "MX" && (
					<Col xs={24} sm={24} md={12} lg={8}>
						<MostUsedPackagesDaily country={country} />
					</Col>
				)}
				<Col xs={24} sm={24} md={12} lg={8}>
					<ShipmentsUserTopDay filteredData={adminAnalytics!} isLoading={isLoading} />
				</Col>
				{country === "MX" && (
					<>
					<Col xs={24} sm={24} md={12} lg={8}>
						<VerifyServices filteredData={{}!} isLoading={isLoading} />
					</Col>
					<Col xs={24} sm={24} md={12} lg={8}>
							<VerifyPaqueterias filteredData={adminAnalytics!} isLoading={isLoading} />
					</Col>
					</>
				)} 
			</Row>
			<Row gutter={[10, 10]}>
				<Col xs={24} sm={24} md={24} lg={12}>
					<Graph
						isLoading={isLoadingShipmentsByDateRange}
						filteredData={dataShiptmentsByDateRange}
						chartType="area"
						filterType="day"
					/>
				</Col>
				<Col xs={24} sm={24} md={24} lg={12}>
				<GraphDailyByService
					isLoading={isLoadingGetDailyShipmentsByService}
					filteredData={getDailyShipmentsService}
					refetchData={refetchGetDailyShipmentsByService}
				/>
				</Col>
			</Row>
		</>
	);
};
